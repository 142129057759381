<template>
  <a-modal :visible="visible" title="发布工单" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
    <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item prop="planned_start_time" label="计划开始时间">
        <a-date-picker
          v-model="dataForm.planned_start_time"
          placeholder="请选择时间"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          show-time
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item prop="planned_end_time" label="计划完成时间">
        <a-date-picker
          v-model="dataForm.planned_end_time"
          placeholder="请选择时间"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          show-time
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item prop="batch_number" label="批号">
        <a-input v-model="dataForm.batch_number" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { productionOrderPublish } from "@/api/production";
import moment from "moment";

export default {
  components: {},
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        planned_start_time: [{ required: true, message: "请选择时间", trigger: "change" }],
        planned_end_time: [{ required: true, message: "请选择时间", trigger: "change" }],
      },
      loading: false,
      dataForm: {},
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          productionOrderPublish({ id: this.item.id, ...this.dataForm })
            .then((data) => {
              this.$message.success("发布成功");
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.dataForm = { planned_start_time: moment().format("YYYY-MM-DD HH:mm:ss") };
      }
    },
  },
};
</script>

<style scoped></style>
